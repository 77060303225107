@charset "utf-8";
@import "mixin", "base";

// <section> .singleで1カラム .single.secondで2カラム

.single {
	padding-top: 60px;
	padding-bottom: 140px;
}

.single__title {
	font-size: (38rem/16);
	// padding-left: 1rem;
	// text-indent: -1rem;
	display: flex;
	align-items: center;
	line-height: 1.3;
	font-weight: 500;
	margin-bottom: .5em;

	padding-left: 2.7rem;
	position:relative;

	@include mq(xs) {
		// font-size: vw425(26);
		font-size: 22px;
		padding-left: 1.2em;
	}

	&::before {
            // display: inline-block;
            content: "";
            // font-size: (28rem/16);
            color: $red;
            position:absolute;
            top:.3em;
            left:0;
            width:.8em;
            height:.8em;
            display: block;
            z-index:1;
            border-radius:50%;
            background-color: $red;

		@include mq(xs) {
			// font-size: (20rem/16);
		}
	}
}

.single-left-img-parent {
	margin-bottom: 16px;
}

.single-flex {
	.second & {
		display: flex;
		justify-content: space-between;

		@include mq(s) {
			display: block;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	@include mq(s) {
		display: block;
		padding-left: 30px;
		padding-right: 30px;
	}
}

//leftblock
.single-article {

	.second & {
		width: calc(100% - 300px - 45px);

		@include mq(s) {
			width: 100%;
		}
	}

	.single_time {
		font-size: (13rem/16);
		font-family: "Rubik";
		display: flex;
		align-items: center;
		color: #767676;

		@include mq(s) {
			font-size: (26rem/16);
		}

		@include mq(xxs) {
			font-size: 1rem;
		}

		&::before {
			display: inline-block;
			content: "";
			background-image: url(../img/event/time.png);
			background-repeat: no-repeat;
			background-size: contain;
			width: 15px;
			height: 15px;
			margin-right: 4px;

			@include mq(s) {
				width: 28px;
				height: 28px;
			}

			@include mq(xs) {
				width: 14px;
				height: 14px;
			}
		}
	}

	.single__timeCategory {
		font-size: 0.85em;
        color: #767676;

		@include mq(xxs) {
			margin-top: 18px;
			margin-bottom: 14px;
		}

        svg{
            display: inline-block; height: 1em; vertical-align: -0.2em;
        }
	}

	.single__category {
		margin-left: 12px;
		// display: inline-block;
		color: $red;
		font-size: 1rem;
		border: 1px solid $red;
		padding: 4px 18px;
		border-radius: 20px;
		min-width: 100px;
		line-height: 1.4;

		@include mq(s) {
			font-size: (28rem/16);
		}

		@include mq(xxs) {
			font-size: 1rem;
		}
	}

	.single-figure {
		width: 100%;
		margin: 0 auto;

		img {
			width: 100%;
			height: auto;
			object-fit: contain;
			font-family: "object-fit: contain;";
		}
	}

	.single-article__footer {

		//ページネーション
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 90px;

		// @include mq(m) {
		//   width: vw1018(517);
		// }

		// @include mq(s) {
		//   // width: 68vw;
		//   width: 100%;
		//   margin-left: 4.58333vw;
		//   padding-right: vw750(34);
		//   // margin-right: 7vw;;
		// }

		@include mq(s) {
			// margin-bottom: 100px;
			flex-wrap: wrap;
			margin-top: 32px;
		}


		.page__btn__prev {
			display: flex;
			align-items: center;
			margin-right: 10%;
			width: 100%;

			@include mq(s) {
				// font-size: (28rem/16);
				margin-right: 4%;
				font-size: 1rem;
			}

			@include mq(xxs) {}

			&::before {
				content: "";
				transform: rotate(45deg);
				border-bottom: 2px solid $red;
				border-left: 2px solid $red;
				width: 8px;
				height: 8px;
				display: inline-block;
				margin-right: 20px;
			}
		}

		.page__txet {
			display: inline-block;
			width: 300px;
			max-width: 100%;
			color: $white;
			background-color: #000;
			padding: 12px 4px;
			border-radius: 30px;
			transition: $time all;
			margin: 0 auto;


			@include mq(xs) {
				width: 180px;
			}

			@include mq(xxs) {
				width: 90%;
				padding: vw425(12) vw425(4);
				border-radius: vw425(30);
			}
		}

		.page__txet:hover {
			background-color: $red;
		}

		.page__archive-link {
			text-align: center;
			font-size: 16px;
			position: relative;
			left: 10px;
			// width: 90%;

			@include mq(xs) {
				// font-size: 1rem;
				line-height: 1.2;

			}


			@include mq(xxs) {
				left: vw425(10);
				font-size: 14px;
			}

		}

		.br {
			display: none;

			@include mq(xs) {
				display: inline;
			}
		}


		.page__archive-link::before {
			display: inline-block;
			content: "";
			background-image: url(../img/common/icon_announserblog.svg);
			background-repeat: no-repeat;
			background-size: auto;
			width: 19px;
			height: 30px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 20px;


			@include mq(xs) {
				left: 10px;
			}

		}














		.page__btn__next {
			color: #000;
			display: flex;
			align-items: center;
			margin-left: 10%;
			width: 100%;

			@include mq(s) {
				// font-size: (28rem/16);
				margin-left: 4%;
				// right: -4%;
				// right: 0;
				font-size: 1rem;
			}

			@include mq(xxs) {
				// font-size: (20rem/16);
			}



			&::after {
				content: "";
				transform: rotate(45deg) translateY(-50%);
				transform: rotate(45deg);
				border-top: 2px solid $red;
				border-right: 2px solid $red;
				width: 8px;
				height: 8px;
				display: inline-block;
				position: relative;

				margin-left: 20px;

				@include mq(s) {}
			}
		}

		//ページネーション



	}

}








.single-aside {
	width: 245px;

	@include mq(s) {
		width: 100%;
	}
}


















//rightblock



.event-right__time {
	font-size: (13rem/16);
	font-family: "Rubik";
	color: #767676;
	padding-left: 16px;


	@include mq(s) {
		font-size: (24rem/16);
	}

	@include mq(xxs) {
		font-size: (18rem/16);
	}
}



///
.blog-aside__btnWrap {
	text-align: right;
	margin-top: 40px;
}

.blog-aside__morebtn {
	font-size: (14rem/16);
	position: relative;
	left: -24px;
	display: inline-block;

	@include mq(s) {
		font-size: (26rem/16);
	}

	@include mq(xxs) {
		font-size: vw425(20);
	}

}

.blog-aside__morebtn::after {
	content: "";
	display: block;
	display: inline-block;
	width: 6px;
	height: 6px;
	position: absolute;
	right: -24px;
	top: calc(50% - 5px);
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	border-right: 2px solid #ff033f;
	border-top: 2px solid #ff033f;

	@include mq(s) {
		width: 12px;
		height: 12px;
		top: calc(50% - 8px);
	}

	@include mq(xxs) {
		width: vw425(12);
		height: vw425(12);
	}

}
