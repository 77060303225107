.single {
  padding-top: 60px;
  padding-bottom: 140px;
}

.single__title {
  font-size: 2.375rem;
  display: flex;
  align-items: center;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: .5em;
  padding-left: 2.7rem;
  position: relative;
}

@media screen and (max-width: 576px) {
  .single__title {
    font-size: 22px;
    padding-left: 1.2em;
  }
}

.single__title::before {
  content: "";
  color: #ff033f;
  position: absolute;
  top: .3em;
  left: 0;
  width: .8em;
  height: .8em;
  display: block;
  z-index: 1;
  border-radius: 50%;
  background-color: #ff033f;
}

.single-left-img-parent {
  margin-bottom: 16px;
}

.second .single-flex {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .second .single-flex {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .single-flex {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.second .single-article {
  width: calc(100% - 300px - 45px);
}

@media screen and (max-width: 768px) {
  .second .single-article {
    width: 100%;
  }
}

.single-article .single_time {
  font-size: 0.8125rem;
  font-family: "Rubik";
  display: flex;
  align-items: center;
  color: #767676;
}

@media screen and (max-width: 768px) {
  .single-article .single_time {
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 425px) {
  .single-article .single_time {
    font-size: 1rem;
  }
}

.single-article .single_time::before {
  display: inline-block;
  content: "";
  background-image: url(../img/event/time.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
  margin-right: 4px;
}

@media screen and (max-width: 768px) {
  .single-article .single_time::before {
    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width: 576px) {
  .single-article .single_time::before {
    width: 14px;
    height: 14px;
  }
}

.single-article .single__timeCategory {
  font-size: 0.85em;
  color: #767676;
}

@media screen and (max-width: 425px) {
  .single-article .single__timeCategory {
    margin-top: 18px;
    margin-bottom: 14px;
  }
}

.single-article .single__timeCategory svg {
  display: inline-block;
  height: 1em;
  vertical-align: -0.2em;
}

.single-article .single__category {
  margin-left: 12px;
  color: #ff033f;
  font-size: 1rem;
  border: 1px solid #ff033f;
  padding: 4px 18px;
  border-radius: 20px;
  min-width: 100px;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .single-article .single__category {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 425px) {
  .single-article .single__category {
    font-size: 1rem;
  }
}

.single-article .single-figure {
  width: 100%;
  margin: 0 auto;
}

.single-article .single-figure img {
  width: 100%;
  height: auto;
  object-fit: contain;
  font-family: "object-fit: contain;";
}

.single-article .single-article__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 90px;
}

@media screen and (max-width: 768px) {
  .single-article .single-article__footer {
    flex-wrap: wrap;
    margin-top: 32px;
  }
}

.single-article .single-article__footer .page__btn__prev {
  display: flex;
  align-items: center;
  margin-right: 10%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .single-article .single-article__footer .page__btn__prev {
    margin-right: 4%;
    font-size: 1rem;
  }
}

.single-article .single-article__footer .page__btn__prev::before {
  content: "";
  transform: rotate(45deg);
  border-bottom: 2px solid #ff033f;
  border-left: 2px solid #ff033f;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 20px;
}

.single-article .single-article__footer .page__txet {
  display: inline-block;
  width: 300px;
  max-width: 100%;
  color: #fff;
  background-color: #000;
  padding: 12px 4px;
  border-radius: 30px;
  transition: 0.6s all;
  margin: 0 auto;
}

@media screen and (max-width: 576px) {
  .single-article .single-article__footer .page__txet {
    width: 180px;
  }
}

@media screen and (max-width: 425px) {
  .single-article .single-article__footer .page__txet {
    width: 90%;
    padding: 2.82353vw 0.94118vw;
    border-radius: 7.05882vw;
  }
}

.single-article .single-article__footer .page__txet:hover {
  background-color: #ff033f;
}

.single-article .single-article__footer .page__archive-link {
  text-align: center;
  font-size: 16px;
  position: relative;
  left: 10px;
}

@media screen and (max-width: 576px) {
  .single-article .single-article__footer .page__archive-link {
    line-height: 1.2;
  }
}

@media screen and (max-width: 425px) {
  .single-article .single-article__footer .page__archive-link {
    left: 2.35294vw;
    font-size: 14px;
  }
}

.single-article .single-article__footer .br {
  display: none;
}

@media screen and (max-width: 576px) {
  .single-article .single-article__footer .br {
    display: inline;
  }
}

.single-article .single-article__footer .page__archive-link::before {
  display: inline-block;
  content: "";
  background-image: url(../img/common/icon_announserblog.svg);
  background-repeat: no-repeat;
  background-size: auto;
  width: 19px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

@media screen and (max-width: 576px) {
  .single-article .single-article__footer .page__archive-link::before {
    left: 10px;
  }
}

.single-article .single-article__footer .page__btn__next {
  color: #000;
  display: flex;
  align-items: center;
  margin-left: 10%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .single-article .single-article__footer .page__btn__next {
    margin-left: 4%;
    font-size: 1rem;
  }
}

.single-article .single-article__footer .page__btn__next::after {
  content: "";
  transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg);
  border-top: 2px solid #ff033f;
  border-right: 2px solid #ff033f;
  width: 8px;
  height: 8px;
  display: inline-block;
  position: relative;
  margin-left: 20px;
}

.single-aside {
  width: 245px;
}

@media screen and (max-width: 768px) {
  .single-aside {
    width: 100%;
  }
}

.event-right__time {
  font-size: 0.8125rem;
  font-family: "Rubik";
  color: #767676;
  padding-left: 16px;
}

@media screen and (max-width: 768px) {
  .event-right__time {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 425px) {
  .event-right__time {
    font-size: 1.125rem;
  }
}

.blog-aside__btnWrap {
  text-align: right;
  margin-top: 40px;
}

.blog-aside__morebtn {
  font-size: 0.875rem;
  position: relative;
  left: -24px;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .blog-aside__morebtn {
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 425px) {
  .blog-aside__morebtn {
    font-size: 4.70588vw;
  }
}

.blog-aside__morebtn::after {
  content: "";
  display: block;
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  right: -24px;
  top: calc(50% - 5px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  border-right: 2px solid #ff033f;
  border-top: 2px solid #ff033f;
}

@media screen and (max-width: 768px) {
  .blog-aside__morebtn::after {
    width: 12px;
    height: 12px;
    top: calc(50% - 8px);
  }
}

@media screen and (max-width: 425px) {
  .blog-aside__morebtn::after {
    width: 2.82353vw;
    height: 2.82353vw;
  }
}
